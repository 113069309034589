<template>
  <base-modal :open="open">
    <h1>{{ tr('Find a patient') }}</h1>
    <input type="text" :placeholder="tr('Search for a patient')" ref="searchInputEl" @keydown="performSearch"
      v-model="searchQuery" :style="searchInputStyle" />
    <button class="search-button" @click.prevent="onSearchForPatient" :style="searchButtonStyle">{{ tr('Search')
    }}</button>
    <table v-if="patients.length !== 0 && searchStatus !== 'searching'">
      <thead ref="patientTableHeader">
        <th class="patient-name-header"><button class="sort-button" @click.prevent="onClickSortTable(0)"><span
              class="underline">{{ tr('Name') }}</span>&nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320" class="sort-icon"
              v-if="currentSortedColumnIndex == 0 && sortOrder" :style="sortIconStyle">
              <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M279.015 243.525h-238c-21.4 0-32.1-25.9-17-41l119-119c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320" class="sort-icon"
              v-if="currentSortedColumnIndex == 0 && !sortOrder" :style="sortIconStyle">
              <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M41 76.475h238c21.4 0 32.1 25.9 17 41l-119 119c-9.4 9.4-24.6 9.4-33.9 0l-119.1-119c-15.1-15.1-4.4-41 17-41z" />
            </svg>
          </button>
        </th>
        <th><button class="sort-button" @click.prevent="onClickSortTable(1)"><span class="underline">{{ tr('Gender')
        }}</span>&nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320" class="sort-icon"
              v-if="currentSortedColumnIndex == 1 && sortOrder" :style="sortIconStyle">
              <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M279.015 243.525h-238c-21.4 0-32.1-25.9-17-41l119-119c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320" class="sort-icon"
              v-if="currentSortedColumnIndex == 1 && !sortOrder" :style="sortIconStyle">
              <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M41 76.475h238c21.4 0 32.1 25.9 17 41l-119 119c-9.4 9.4-24.6 9.4-33.9 0l-119.1-119c-15.1-15.1-4.4-41 17-41z" />
            </svg>
          </button>
        </th>
        <th><button class="sort-button" @click.prevent="onClickSortTable(2)"><span class="underline">{{ tr('Birthdate')
        }}</span>&nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320" class="sort-icon"
              v-if="currentSortedColumnIndex == 2 && sortOrder" :style="sortIconStyle">
              <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M279.015 243.525h-238c-21.4 0-32.1-25.9-17-41l119-119c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320" class="sort-icon"
              v-if="currentSortedColumnIndex == 2 && !sortOrder" :style="sortIconStyle">
              <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M41 76.475h238c21.4 0 32.1 25.9 17 41l-119 119c-9.4 9.4-24.6 9.4-33.9 0l-119.1-119c-15.1-15.1-4.4-41 17-41z" />
            </svg>
          </button>
        </th>
        <th><button class="sort-button" @click.prevent="onClickSortTable(3)"><span class="underline">{{ tr('MRN')
        }}</span>&nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320" class="sort-icon"
              v-if="currentSortedColumnIndex == 3 && sortOrder" :style="sortIconStyle">
              <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M279.015 243.525h-238c-21.4 0-32.1-25.9-17-41l119-119c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320" class="sort-icon"
              v-if="currentSortedColumnIndex == 3 && !sortOrder" :style="sortIconStyle">
              <!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
              <path
                d="M41 76.475h238c21.4 0 32.1 25.9 17 41l-119 119c-9.4 9.4-24.6 9.4-33.9 0l-119.1-119c-15.1-15.1-4.4-41 17-41z" />
            </svg>
          </button>
        </th>
      </thead>
      <tbody class="patient-table-body">
        <tr v-for="patient in patients" :key="patient.id" @click.prevent="onSelectPatient(patient)"
          :style="patientTableRowStyle">
          <td class="patient-name">
            <button class="select-patient">{{ patient.name }}</button>
          </td>
          <td>
            {{ patient.gender }}
          </td>
          <td>
            {{ patient.birthdate }}
          </td>
          <td class="font-monospace" :title="patient.id">
            {{ shortenId(patient.id) }}
          </td>
        </tr>
      </tbody>
    </table>

    <p v-if="patients.length === 0 && searchStatus === 'done'">{{ tr('No patients found.') }}</p>
    <base-spinner class="base-spinner" v-if="searchStatus === 'searching'"></base-spinner>

    <div class="modal-footer" :style="footerStyle">
      <button class="modal-footer-button" :style="buttonStyle" @click.prevent="onCancel">{{ tr('Cancel') }}</button>
    </div>
  </base-modal>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'

import BaseModal from '@/components/ui/BaseModal.vue'
import BaseSpinner from '@/components/ui/icons/BaseSpinner.vue'

import { search } from './search.js'
import { getScrollbarWidth, shortenId, themeColors } from '@/common/shared.js'

const columnCompareFuncs = {
  0: (a, b) => a.name.localeCompare(b.name),
  1: (a, b) => a.gender.localeCompare(b.gender),
  2: (a, b) => a.birthdate.localeCompare(b.birthdate),
  3: (a, b) => a.id.localeCompare(b.id),
}
const compareFunc = (columnIndex, ascending) => {
  return (a, b) => {
    const f = columnCompareFuncs[columnIndex]
    return ascending ? f(a, b) : -f(a, b)
  }
}

export default {
  components: {
    BaseModal,
    BaseSpinner,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore()
    const tr = inject('tr')
    const tutorialNext = inject('tutorialNext')

    const searchQuery = ref('')

    const patients = ref([])

    const patientTableHeader = ref(null)
    const onSearchForPatient = async () => {
      tutorialNext()
      const query = searchQuery.value
      searchStatus.value = 'searching'
      const results = await search(query)
      patients.value = results
      sortTable(0)
      searchQuery.value = ''
      searchStatus.value = 'done'

      setTimeout(() => {
        if (patientTableHeader.value) {
          const scrollbarWidth = getScrollbarWidth()
          patientTableHeader.value.style.width = `${patientTableHeader.value.offsetWidth - scrollbarWidth}px`
        }
      }, 50)
    }

    const searchInputEl = ref(null)
    const isOpen = computed(() => props.open)
    watch(isOpen, (newValue) => {
      if (!newValue) { return }
      setTimeout(() => searchInputEl.value.focus(), 100)
    })

    const performSearch = (e) => {
      if (e.keyCode !== 13) { return }
      onSearchForPatient()
    }

    const onSelectPatient = (patient) => {
      store.dispatch('setSessionPatient', { patient, publish: true })
      context.emit('close')
      setTimeout(tutorialNext, 50)
    }

    const searchStatus = ref('pending')

    const onCancel = () => {
      context.emit('close')
    }

    const searchButtonStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].buttonBorderColor,
        color: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        border: `${themeColors[store.getters.currentThemeName].buttonBorderColor}`,
      }
    })

    const searchInputStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].toolInputBackgroundColor,
        borderColor: themeColors[store.getters.currentThemeName].modalTextColor,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
      }
    })

    const sortIconStyle = computed(() => {
      return {
        fill: themeColors[store.getters.currentThemeName].modalTextColor,
      }
    })

    const buttonStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        color: themeColors[store.getters.currentThemeName].accentColor,
        borderColor: `${themeColors[store.getters.currentThemeName].accentColor}`,
      }
    })

    const footerStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
      }
    })

    const patientTableRowStyle = computed(() => {
      return {
        '--tr-even-row-background-color': themeColors[store.getters.currentThemeName].alternatingTableRowsBackgroundColor,
        '--tr-hover-background-color': themeColors[store.getters.currentThemeName].tableRowHoverBackgroundColor,
      }
    })

    let lastSortedColumnIndex = 0
    let lastSortOrder = true
    const currentSortedColumnIndex = ref(0)
    const sortOrder = ref(true)

    const onClickSortTable = (index) => {
      if (index !== currentSortedColumnIndex.value) {
        lastSortedColumnIndex = currentSortedColumnIndex.value
        lastSortOrder = sortOrder.value
        sortOrder.value = true
      } else {
        sortOrder.value = !sortOrder.value
      }
      currentSortedColumnIndex.value = index
      sortTable(index)
    }

    const sortTable = (index) => {
      const firstCompare = compareFunc(index, sortOrder.value)
      const secondCompare = compareFunc(lastSortedColumnIndex, lastSortOrder)
      const bothCompares = (a, b) => {
        const result1 = firstCompare(a, b)
        if (result1 === 0) { return secondCompare(a, b) }
        return result1
      }
      patients.value.sort(bothCompares)
    }

    return {
      buttonStyle,
      currentSortedColumnIndex,
      footerStyle,
      onCancel,
      onClickSortTable,
      onSearchForPatient,
      onSelectPatient,
      patients,
      patientTableHeader,
      patientTableRowStyle,
      performSearch,
      searchButtonStyle,
      searchInputEl,
      searchInputStyle,
      searchQuery,
      searchStatus,
      shortenId,
      sortIconStyle,
      sortOrder,
      sortTable,
      tr,
    }
  },
}
</script>

<style lang="scss" scoped>
dialog {
  overflow-y: hidden;
}

h1 {
  font-size: 2rem;
  padding-top: 1rem;
}

input {
  font: inherit;
  border: none;
  border-bottom: 1px solid #000;
  padding: 5px;

  &:hover,
  &:focus {
    transform: scale(1.2);
  }
}

.search-button {
  font: inherit;
  margin: 1rem 3rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 40px;

  &:hover,
  &:focus {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }
}

table {
  width: 100%;
  margin: 0;
  margin-top: 1rem;
  text-align: left;
  border-collapse: collapse;
}

.sort-button {
  font: inherit;
  color: inherit;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover,
  &:focus,
  &:focus:not(:focus-visible) {
    transform: none;

    &:hover {
      transform: none;
    }
  }
}

.sort-button:hover .underline {
  text-decoration: underline;
}

.sort-icon {
  width: 0.8rem;
}

thead,
tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

tbody {
  display: block;
  max-height: 22rem;
  overflow-y: scroll;
}

tr {
  display: table;
  cursor: pointer;
}

tr:last-of-type {
  margin-bottom: 4.475rem;
}

td {
  padding: 0.75rem 0;
}

.patient-name,
.patient-name-header {
  padding-left: 1.5rem;
}

.select-patient {
  font: inherit;
  color: inherit;
  padding: 0;
  font-size: 1em;
  background: none;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    transform: scale(1.3);
  }
}

.font-monospace {
  font-family: monospace;
  font-size: 1.2rem;
}

.base-spinner {
  display: block;
  margin: auto;
  width: 100px;
  height: 100px;
  margin-bottom: 6rem;
}

// The following did not work:
// .base-modal ::v-deep(.dialog) {
//   min-height: 300px;
// }

.patient-table-body>tr:nth-child(even) {
  background-color: #333; // fallback
  background-color: var(--tr-even-row-background-color);
}

.patient-table-body>tr:hover {
  background-color: #555; // fallback
  background-color: var(--tr-hover-background-color);
}

:focus:not(:focus-visible) {
  transform: scale(1);

  &:hover {
    transform: scale(1.2);
  }
}

.modal-footer {
  width: 100%;
  margin: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.modal-footer-button {
  font: inherit;
  margin: 1rem 3rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1em;
  border-radius: 40px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;


  &:hover,
  &:focus {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }

  &:focus:not(:focus-visible) {
    transform: scale(1);

    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
